import React from 'react';

// Import styles 
import styles from './ParagraphOne.module.css';

function ParagraphOne({additionalClassNames, onClick, children}) {
    return ( 
        <p className={`${additionalClassNames} ${styles.paragraphOne}`} onClick={onClick}>{children}</p>
    );
}

export default ParagraphOne;