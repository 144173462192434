import React, {useState} from 'react';
import emailjs from 'emailjs-com';

// Import styles
import styles from './JoinTheTeam.module.css';

// Import components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import HeadingOne from '../components/HeadingOne';

import BaseButton from '../components/BaseButton';

const PUBLIC_KEY = 'ZsS1KbpZvU-Il983J'
const SERVICE_ID = 'service_arp2eao';
const TEMPLATE_ID = 'template_p0lff9b';

function JoinTheTeamPage() {
    // Form variables
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [phoneNumber, setPhoneNumber] = useState();
    const [emailAddress, setEmailAddress] = useState('');

    const [streetAddress, setStreetAddress] = useState('');
    const [addressLineTwo, setAddressLineTwo] = useState('');
    const [applicantCity, setApplicantCity] = useState('');
    const [applicantState, setApplicantState] = useState('Michigan');
    const [applicantZipCode, setApplicantZipCode] = useState('');

    const [hostCheck, setHostCheck] = useState('unchecked');
    const [busserBarbackCheck, setBusserBarbackCheck] = useState('unchecked');
    const [serverWaiterCheck, setServerWaiterCheck] = useState('unchecked');
    const [bartenderMixoloCheck, setBartenderMixoloCheck] = useState('unchecked');
    const [bartenderCheck, setBartenderCheck] = useState('unchecked');

    const [USEligibleYes, setUSEligibleYes] = useState('unchecked');
    const [USEligibleNo, setUSEligibleNo] = useState('unchecked');
    const [USEligibleAnswer, setUSEligibleAnswer] = useState("Unselected");

    const [applicantLanguages, setApplicantLanguages] = useState('');
    const [applicantPreferredLanguage, setApplicantPreferredLanguage] = useState('');

    const [fullTimeCheck, setFullTimeCheck] = useState('unchecked');
    const [partTimeCheck, setPartTimeCheck] = useState('unchecked');

    const [startDate, setStartDate] = useState();

    const [resumeFile, setResumeFile] = useState();

    // Form variable onChange handlers
    function handleFirstNameChange(e) {
        const name = e.target.value;
        setFirstName(name);
    }

    function handleLastNameChange(e) {
        const name = e.target.value;
        setLastName(name);
    }

    function handlePhoneNumberChange(e) {
        const number = e.target.value;
        setPhoneNumber(number);
    }

    function handleEmailAddressChange(e) {
        const address = e.target.value;
        setEmailAddress(address);
    }

    function handleStreetAddressChange(e) {
        const address = e.target.value;
        setStreetAddress(address);
    }

    function handleAddressLineTwoChange(e) {
        const address = e.target.value;
        setAddressLineTwo(address);
    }

    function handleApplicantCityChange(e) {
        const city = e.target.value;
        setApplicantCity(city);
    }

    function handleApplicantStateChange(e) {
        const state = e.target.value;
        setApplicantState(state);
    }

    function handleApplicantZipCodeChange(e) {
        const code = e.target.value;
        setApplicantZipCode(code);
    }

    function handleHostCheck(e) {
        const check = e.target.value;
        if (check === 'unchecked') {
            setHostCheck('checked')
        } else {
            setHostCheck('unchecked');
        }
    }

    function handleBusserBarbackChange(e) {
        const check = e.target.value;
        if (check === 'unchecked') {
            setBusserBarbackCheck('checked')
        } else {
            setBusserBarbackCheck('unchecked');
        }
    }

    function handleServerWaiterChange(e) {
        const check = e.target.value;
        if (check === 'unchecked') {
            setServerWaiterCheck('checked')
        } else {
            setServerWaiterCheck('unchecked');
        }
    }
    
    function handleBartenderMixoloChange(e) {
        const check = e.target.value;
        if (check === 'unchecked') {
            setBartenderMixoloCheck('checked')
        } else {
            setBartenderMixoloCheck('unchecked');
        }
    }

    function handleBartenderChange(e) {
        const check = e.target.value;
        if (check === 'unchecked') {
            setBartenderCheck('checked')
        } else {
            setBartenderCheck('unchecked');
        }
    }

    function handleUSEligibilityYesChange(e) {
        const check = e.target.value;
        if (check === 'unchecked') {
            setUSEligibleYes('checked');
            setUSEligibleNo('unchecked');
            setUSEligibleAnswer('Yes');
        }
    }

    function handleUSEligibilityNoChange(e) {
        const check = e.target.value;
        if (check === 'unchecked') {
            setUSEligibleNo('checked');
            setUSEligibleYes('unchecked');
            setUSEligibleAnswer('No');
        }
    }

    function handleApplicantLanguagesChange(e) {
        const languages = e.target.value;
        setApplicantLanguages(languages);
    }

    function handleApplicantPreferredLanguageChange(e) {
        const language = e.target.value;
        setApplicantPreferredLanguage(language);
    }

    function handleFullTimeCheckChange(e) {
        const check = e.target.value;
        if (check === 'unchecked') {
            setFullTimeCheck('checked')
        } else {
            setFullTimeCheck('unchecked');
        }
    }

    function handlePartTimeCheckChange(e) {
        const check = e.target.value;
        if (check === 'unchecked') {
            setPartTimeCheck('checked')
        } else {
            setPartTimeCheck('unchecked');
        }
    }

    function handleStartDateChange(e) {
        const date = e.target.value;
        setStartDate(date);
    }

    function handleResumeFileChange(e) {
        setResumeFile(e.target.files[0]);
    }

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission

        // Sends application to Rocky's email
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, event.target, PUBLIC_KEY)
            .then((result) => {
                alert("Application sent successfully! We'll get back to you soon.");
                window.location.reload();
            }, (error) => {
                alert('There has been an error while sending the application. Try again.');
                console.log(error.text);
            });

    };

    return ( 
        <div className={styles.joinTheTeamPage}>
            {/* ########## NAVIGATION BAR ########## */}
            <Navbar additionalClassNames={styles.navBar}></Navbar>

            {/* ########## JOIN THE TEAM PAGE CONTENT ########## */}
            <div className={styles.joinTheTeamPageContent}>
                <HeadingOne>Join The Team</HeadingOne>

                {/* Application Form */}
                <form className={styles.applicationForm} onSubmit={handleSubmit}>

                    {/* Name field */}
                    <p className={styles.label}>Name</p>
                    <div className={styles.twoColumnInputRow}>
                        <div>
                            <input name='firstName' id='firstName' type='text' value={firstName} onChange={handleFirstNameChange} className={`${styles.textBox}`} required/>
                            <label htmlFor="firstName" className={`${styles.smallLabel}`}>First</label>
                        </div>
                        <div>
                            <input name='lastName' id='lastName' type='text' value={lastName} onChange={handleLastNameChange} className={`${styles.textBox}`} required/>
                            <label htmlFor="lastName" className={`${styles.smallLabel}`}>Last</label>
                        </div>
                    </div>

                    {/* Contact Information */}
                    <div className={`${styles.twoColumnInputRow} ${styles.divisionMargin}`}>
                        <div>
                            <p className={styles.label}>Phone Number</p>
                            <input name='phoneNumber' id='phoneNumber' type='tel' value={phoneNumber} onChange={handlePhoneNumberChange} className={`${styles.textBox}`} required/>
                        </div>
                        <div>
                            <p className={styles.label}>Email Address</p>
                            <input name='emailAddress' id='emailAddress' type='text' value={emailAddress} onChange={handleEmailAddressChange} className={`${styles.textBox}`} required/>
                        </div>
                    </div>

                    {/* Applicant Mailing Address */}
                    <p className={`${styles.label} ${styles.divisionMargin}`}>Applicant Mailing Address</p>
                    <input name='streetAddress' id='streetAddress' type='text' value={streetAddress} onChange={handleStreetAddressChange} className={`${styles.textBox} ${styles.divisionSmallMargin}`} required/>
                    <label htmlFor="streetAddress" className={`${styles.smallLabel}`}>Street Address</label>

                    <input name='addressLineTwo' id='addressLineTwo' type='text' value={addressLineTwo} onChange={handleAddressLineTwoChange} className={`${styles.textBox} ${styles.divisionSmallMargin}`}/>
                    <label htmlFor="addressLineTwo" className={`${styles.smallLabel}`}>Address Line Two</label>

                    <div className={`${styles.twoColumnInputRow} ${styles.divisionSmallMargin}`}>
                        <div>
                            <input name='applicantCity' id='applicantCity' type='text' value={applicantCity} onChange={handleApplicantCityChange} className={`${styles.textBox}`} required/>
                            <label htmlFor="applicantCity" className={`${styles.smallLabel}`}>City</label>
                        </div>
                        <div>
                            <select name='applicantState' id='applicantState' value={applicantState} onChange={handleApplicantStateChange} className={`${styles.textBox}`} required>
                                <option value="MI">Michigan</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                            </select>
                            <label htmlFor="applicantState" className={`${styles.smallLabel}`}>State</label>
                        </div>
                    </div>

                    <div className={`${styles.twoColumnInputRow} ${styles.divisionSmallMargin}`}>
                        <div>
                            <input name='applicantZipCode' id='applicantZipCode' type='text' value={applicantZipCode} onChange={handleApplicantZipCodeChange} className={`${styles.textBox}`} required/>
                            <label htmlFor="applicantCity" className={`${styles.smallLabel}`}>Zip Code</label>
                        </div>
                        <div>
                            
                        </div>
                    </div>

                    {/* Applicant Position Choices */}
                    <p className={`${styles.label} ${styles.divisionMargin}`}>Which Positions Are You Applying For? (Select at least one)</p>
                    <input type="checkbox" id="position1" name="position1" value={hostCheck} onChange={handleHostCheck}/>
                    <label className={`${styles.checkboxLabel}`} for="position1">Host</label> <br/>

                    <input type="checkbox" id="position2" name="position2" value={busserBarbackCheck} onChange={handleBusserBarbackChange}/>
                    <label className={`${styles.checkboxLabel}`} for="position2">Busser/Backbar</label> <br/>

                    <input type="checkbox" id="position3" name="position3" value={serverWaiterCheck} onChange={handleServerWaiterChange}/>
                    <label className={`${styles.checkboxLabel}`} for="position3">Server/Waiter</label> <br/>

                    <input type="checkbox" id="position4" name="position4" value={bartenderMixoloCheck} onChange={handleBartenderMixoloChange}/>
                    <label className={`${styles.checkboxLabel}`} for="position4">Mixologist</label> <br/>

                    <input type="checkbox" id="position5" name="position5" value={bartenderCheck} onChange={handleBartenderChange}/>
                    <label className={`${styles.checkboxLabel}`} for="position5">Bartender</label> <br/>

                    {/* Applicant US Work Elegibility */}
                    <p className={`${styles.label} ${styles.divisionMargin}`}>If hired, can you proof that you are eligible to work in the US?</p>
                    <input className={styles.invisible} name='USEligibleFormAnswer' id='USEligibleFormAnswer' type='text' value={USEligibleAnswer}/>
            
                    <input type="radio" id="USEligibleYes" name="USEligible" value={USEligibleYes} onChange={handleUSEligibilityYesChange}/>
                    <label className={`${styles.checkboxLabel}`} for="USEligibleYes">Yes</label> <br/>

                    <input type="radio" id="USEligibleNo" name="USEligible" value={USEligibleNo} onChange={handleUSEligibilityNoChange}/>
                    <label className={`${styles.checkboxLabel}`} for="USEligibleNo">No</label> <br/>

                    {/* Languages */}
                    <div className={`${styles.twoColumnInputRow} ${styles.divisionSmallMargin}`}>
                        <div>
                            <p className={styles.label}>What languages do you speak fluently?</p>
                            <input name='applicantLanguages' id='applicantLanguages' type='text' value={applicantLanguages} onChange={handleApplicantLanguagesChange} className={`${styles.textBox}`} required/>
                        </div>
                        <div>
                            
                        </div>
                    </div>

                    <div className={`${styles.twoColumnInputRow} ${styles.divisionSmallMargin}`}>
                        <div>
                            <p className={styles.label}>What language is your preferred language?</p>
                            <input name='applicantPreferredLanguage' id='applicantPreferredLanguage' type='text' value={applicantPreferredLanguage} onChange={handleApplicantPreferredLanguageChange} className={`${styles.textBox}`} required/>
                        </div>
                        <div>
                            
                        </div>
                    </div>

                    {/* Part-time and Full-time Checkboxes */}
                    <p className={`${styles.label} ${styles.divisionMargin}`}>Are you seeking?:</p>
                    <input type="checkbox" id="time1" name="time1" value={fullTimeCheck} onChange={handleFullTimeCheckChange}/>
                    <label className={`${styles.checkboxLabel}`} for="time1">Full-time employment</label> <br/>

                    <input type="checkbox" id="time2" name="time2" value={partTimeCheck} onChange={handlePartTimeCheckChange}/>
                    <label className={`${styles.checkboxLabel}`} for="time2">Part-time employment</label> <br/>

                    {/* Start Date Input*/}
                    <p className={`${styles.label} ${styles.divisionMargin}`}>What date would you be available to start work?</p>
                    <input type="date" id="startDate" name="startDate" value={startDate} onChange={handleStartDateChange} className={`${styles.dateBox}`} required/>

                    {/* Resume Submission */}
                    <p className={`${styles.label} ${styles.divisionMargin}`}>Please upload your resume</p>
                    <input id='resume' name='resume' type="file" accept='.pdf' onChange={handleResumeFileChange} className={styles.resumeUploadBox} required/>

                    <input type="submit" value="Submit Application" className={`${styles.submitButton} ${styles.divisionMargin}`}/>

                </form>
                
            </div>

            {/* ########## FOOTER ########## */}
            <Footer />
        </div>
    );
}

export default JoinTheTeamPage;