export function toastOnlineOrderLink() {
    window.location.href = 'https://order.toasttab.com/online/taco-tequila-y-mas-31660-john-r-road';
}

export function googleMapsLink() {
    window.location.href = "https://maps.app.goo.gl/PG7ByyR1aJG32buBA";
}

export function reservationsLink() {
    alert('If reservations through toast are not available, please call (248) 951-8179');
    window.location.href = "https://tables.toasttab.com/restaurants/9bfddca0-598e-4460-96cc-78aa1520db25/reserve";
}