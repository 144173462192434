import React, {useState} from 'react';
import styles from './Drinks.module.css'

// Import components
import Navbar from '../components/Navbar';
import HeadingOne from '../components/HeadingOne';
import Footer from '../components/Footer';
import QuickAccessBar from '../components/QuickAccessBar';

// Import media
import DrinksMenuPageOne from '../assets/menu_pages/drinks_one.jpg';
import DrinksMenuPageTwo from '../assets/menu_pages/drinks_two.jpg';

import PictureOne from '../assets/drinks menu/tequila_background.jpg';
import PictureTwo from '../assets/drinks menu/mezcal_background.webp';
import PictureThree from '../assets/drinks menu/whiskey_background.webp';
import PictureFour from '../assets/drinks menu/rum_background.jpg';
import PictureFive from '../assets/drinks menu/gin_background.avif';
import PictureSix from '../assets/drinks menu/vodka_background.jpg';
import PictureSeven from '../assets/drinks menu/brandy_background.jpg';
import PictureEight from '../assets/drinks menu/congac_background.webp';
import PictureNine from '../assets/drinks menu/scotch_background.webp';

// Drink list map
const liquorMap = {
    tequila: {
        imageUrl: PictureOne,
        drinks: ['Jose Cuervo Especial Silver', 
                'Jose Cuervo Especial Gold', 
                'Jose Cuervo Tradicional Reposado',
                'Don Julio Blanco',
                'Don Julio Reposado',
                'Don Julio Añejo',
                'Don Julio 1942',
                'Patron Silver',
                'Patron Añejo Cristalino',
                'Casamigos Cristalino',
                'Casamigos Reposado',
                'Casamigos Añejo',
                'Casamigos Blanco',
                '1800 Silver',
                'Corralejo Gran Añejo',
                'Corralejo Triple Distilled Reposado',
                'Gran Centenario Plata',
                'Hornitos Reposado',
                'Hornitos Blanco',
                'Espolon Blanco',
                'Espolon Reposado',
                'Espolon Añejo',
                'El Jimador Silver',
                'Maestro Dobel Diamond',
                'El Padrino Cristalino Añejo',
                'El Tesoro Platinum',
                'Cantera Negra Silver',
                'Pantalones Blanco',
                'Tapatio Blanco',
                'Teramana Reposado',
                'Teramana Blanco',
                'Teremana Añejo',
                'Lunazul Blanco',
                'Lunazul Reposado',
                'Lunazul Añejo',
                'Tres Agaves Blanco',
                'Tres Agaves Reposado',
                'Tres Agaves Añejo',
                'Cazadores Blanco',
                'Cazadores Reposado',
                'Milagro Blanco',
                'Milagro Reposado Reserve',
                'Casa Noble Blanco',
                'Herradura Silver',
                'Herradura Reposado',
                'Herradura Ultra Añejo',
                'Avion Blanco',
                'Organic Lalo Blanco',
                'Clase Azul Blanco',
                'Clase Azul Reposado',
                'Tres Generaciones',
                'La Gritona Reposado',
                'Casa Dragones',
                'Cascabel Blanco',
                'Cascabel Reposado']
    },
    mezcal: {
        imageUrl: PictureTwo,
        drinks: ['400 Conejos Joven Oaxaca', 
                'Del Maguey Vida', 
                'Ilegal joven',
                'Montelobos',
                'Casamigos Joven',
                'Xicaru Silver',
                'Xicaru Reposado',
                'Paloma Espadin',
                'Siete Misterios',
                'Tres Papalote']
    },
    whiskey: {
        imageUrl: PictureThree,
        drinks: ['Buchanan 12Y', 
                'Jack Daniels', 
                'Jameson',
                'Woodford Reserve',
                'Makers Mark',
                'Basil Hayden',
                'Crown Royal']
    },
    rum: {
        imageUrl: PictureFour,
        drinks: ['Bacardi', 
                'Captain Morgan', 
                'Malibu']
    },
    gin: {
        imageUrl: PictureFive,
        drinks: ['Tanqueray', 
                'Bombay Sapphire', 
                'Hendricks', 
                'Valentine’s Liberator']
    },
    vodka: {
        imageUrl: PictureSix,
        drinks: ['Absolut Vanilla', 
                'Skyy Citrus', 
                'Skyy Raspberry', 
                'Grey Goose',
                'Chopin',
                'Titos',
                'Smirnoff Tamarindo']
    },
    brandy: {
        imageUrl: PictureSeven,
        drinks: ['Christian Brothers']
    },
    congac: {
        imageUrl: PictureEight,
        drinks: ['Hennessy', 
                'Courvoisier']
    },
    scotch: {
        imageUrl: PictureNine,
        drinks: ['Johnny Walker Black', 
                'Glenlivet 12Y', 
                'Macallan 12 Y']
    }
};


function Drinks() {
    const [selectedCategory, setSelectedCategory] = useState('');

    function handleCategorySelection(e) {
        const categoryId = e.target.id.split('_')[0];
        const categoryList = document.getElementById(`${categoryId}_list`);

        // If there is a category already selected, minimize it
        if (selectedCategory !== '') {
            const previousCategory = document.getElementById(`${selectedCategory}_list`);
            previousCategory.style.height = "0rem";
        }

        if (categoryId === selectedCategory) {
            setSelectedCategory('');
        } else {
            categoryList.style.height = 'fit-content';
            setSelectedCategory(categoryId);
            document.getElementById(categoryId).scrollIntoView({ behavior: 'auto', block: 'center' });
        }
        
    }

    return (
        <div className={styles.drinksPage}>
            {/* ########## NAVIGATION BAR ########## */}
            <Navbar additionalClassNames={styles.navBar} background={'transparent'} page={'drinks'}></Navbar>

            {/* ########## QUICK ACCESS BAR ########## */}
            <QuickAccessBar />

            {/* Ghost space (Fixes weird blank space on top of the page) */}
            <div className={styles.ghostSpace}></div>

            {/* ########## PAGE CONTENT ########## */}
            <div className={styles.drinksPageContent}>
                {/* ########## TITLE ########## */}
                <HeadingOne additionalClassNames={styles.drinksPageTitle}>DRINKS MENU</HeadingOne>

                <div className={styles.drinksMenuPageContainer}>
                    <img className={styles.drinksMenuPage} src={DrinksMenuPageOne} alt='Drinks Menu Page'/>
                    <img className={styles.drinksMenuPage} src={DrinksMenuPageTwo} alt='Drinks Menu Page'/>
                </div>

                {/* DRINKS LISTS */}
                <HeadingOne additionalClassNames={styles.drinksPageTitle}>OUR LIQUORS</HeadingOne>
                <div className={styles.drinksListContainer}>
                    {/* Iterate over all the drinks and their categories */}
                    {Object.entries(liquorMap).map(([category, { imageUrl, drinks }]) => (
                        <div>
                            <div  className={`${styles.categoryTitleContainer}`} style={{backgroundImage: `url(${imageUrl})`, backgroundSize: "cover",backgroundPosition: "center"}}>
                                <div className={styles.categoryTitleTransparentContainer} id={`${category}`} onClick={handleCategorySelection}>
                                    <h2 className={styles.categoryTitle} id={`${category}_title`}>{category}</h2>
                                </div>
                            </div>

                            <div className={styles.categoryList} id={`${category}_list`}>
                                {drinks.map((drink, index) => (
                                    <p className={styles.drinkParagraph}>{drink}</p>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

            </div>


            <Footer></Footer>
        </div>
    );
}

export default Drinks;