import React, {useEffect} from 'react';
import { NavLink, useLocation } from 'react-router-dom';

// Import utils.js
import { toastOnlineOrderLink, googleMapsLink, reservationsLink } from '../../utils';

// Import styles
import styles from './WelcomeScreenMobile.module.css';

// Import media
import TransparentLogo from '../../assets/transparent_logo.png';

// Import components
import BaseButton from '../BaseButton';

function WelcomeScreenMobile({additionalClassnames}) {
    const location = useLocation();

    function handleOptionSelect() {
        document.getElementById("welcomeScreenMobile").style.display = "none";
        document.body.style.overflow = 'auto';
    
        window.scrollTo({
            top: 0
        });
    }

    // This useEffect ensures that the welcome page is visible only when the user gets to any other page
    // that is not the /drinks or /join-the-team pages
    useEffect(() => {
        // Excludes the pages below
        if (location.pathname !== '/drinks' && location.pathname !== '/join-the-team' && location.pathname !== '/bar-rail-specials') {
            // Makes sure that the viewport is a mobile device
            if (window.innerWidth <= 800) {
                document.getElementById("welcomeScreenMobile").style.display = "block";
                document.body.style.overflow = 'auto';
            }
        }
    }, []);

    return ( 
        <div id='welcomeScreenMobile' className={styles.welcomeScreenMobile} style={{backgroundColor: 'transparent'}}>
            <div className={styles.blurredScreen}>
                <img className={styles.Logo} src={TransparentLogo} alt='Log'/>

                <div className={styles.optionsContainer}>
                    
                    <p className={styles.navLink} onClick={toastOnlineOrderLink}>Order Online</p>

                    <NavLink className={styles.navLink} onClick={handleOptionSelect} to="/menu">
                        <p>View Menu</p>
                    </NavLink>
                    
                    <p className={styles.navLink} onClick={reservationsLink}>Reservations</p>
                    
                    <p className={styles.navLink} onClick={googleMapsLink}>Directions</p>

                    <BaseButton text={"CONTINUE TO SITE"} onClick={handleOptionSelect}/>
                </div>
            </div>
        </div>
    );
}

export default WelcomeScreenMobile;