import React from 'react';
import { NavLink } from "react-router-dom";

// Import styles
import styles from './Footer.module.css';

// Import utils.js
import { reservationsLink } from '../../utils';

// Import components
import HeadingTwo from '../HeadingTwo';
import HeadingThree from '../HeadingThree';
import ParagraphOne from '../ParagraphOne';

function Footer() {
    // Scroll to top of the page upon page selection
    const handleOptionSelection = () => {
        window.scrollTo({
            top: 0
        });
    };

    return ( 
        <div className={styles.footer}>
            <div className={styles.footerContentContainer}>
                {/* Map */}
                <div className={styles.footerContainer}>
                    <iframe className={styles.footerMap} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5880.771282581795!2d-83.10886052399698!3d42.525862124610676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8824c50b52dd3f17%3A0x7af18acd06f603f1!2sTaco%20Tequila%20Y%20Mas!5e0!3m2!1sen!2sus!4v1723405951839!5m2!1sen!2sus" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='Taco Tequika Y Mas Location'></iframe>
                </div>

                {/* Contact information */}
                <div className={styles.contactInfoContainer}>
                    <HeadingThree>TACO TEQUILA Y MAS</HeadingThree>
                    <ParagraphOne>31660 John R Rd, Madison Heights, MI</ParagraphOne>
                    <ParagraphOne>(248) 951-8179</ParagraphOne>
                    <ParagraphOne>(248) 951-8962</ParagraphOne>
                    <NavLink onClick={handleOptionSelection} className={`${styles.footerLink}`} to="/join-the-team"><ParagraphOne additionalClassNames={styles.footerLinkParagraph}>Join The Team</ParagraphOne></NavLink>
                    <ParagraphOne additionalClassNames={styles.footerLinkParagraph} onClick={reservationsLink}>Make a Reservation</ParagraphOne>
                </div>

                {/* Hours */}
                <div className={styles.hoursContainer}>
                    <HeadingThree>HOURS</HeadingThree>
                    <p className={styles.hours}>Mon–Thur 11am–10pm</p>
                    <p className={styles.hours}>Fri–Sat 11am–11pm</p>
                    <p className={styles.hours}>Sun 11am–10pm</p>
                </div>
            </div>
            <ParagraphOne additionalClassNames={styles.whaleMedia}>Made by <a className={styles.companyNameLink} href='https://www.instagram.com/whale_media_agency/' target='blank'>Whale Media</a> 🐋</ParagraphOne>
        </div>
    );
}

export default Footer;