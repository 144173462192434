import React from 'react';

// Import Components
import Navbar from '../components/Navbar';
import QuickAccessBar from '../components/QuickAccessBar';
import Footer from '../components/Footer';

// Import styles
import styles from './BarRail.module.css';

// Import media
import BarRailSpecials from '../assets/menu_pages/bar_rail.jpg';

function BarRail() {
    return ( 
        <div className={styles.barRailPage}>
            {/* ########## NAVIGATION BAR ########## */}
            <Navbar additionalClassNames={styles.navBar} background={'transparent'} page={'drinks'}></Navbar>

            {/* ########## QUICK ACCESS BAR ########## */}
            <QuickAccessBar />

            {/* Ghost space (Fixes weird blank space on top of the page) */}
            <div className={styles.ghostSpace}></div>

            {/* ########## BAR RAIL PAGE CONTENT ########## */}
            <div className={styles.barRailPageContent}>
                <img src={BarRailSpecials} className={styles.barRailSpecialsImage} alt='Bar rail specials'/>
            </div>

            {/* ########## FOOTER ########## */}
            <Footer />
        </div>
    );
}

export default BarRail;